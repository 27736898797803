<template class="bl_payment_pop">
  <div class="mb10">
    <template v-if="memberDetail.userCtrCd === 'KR'">
      <h2 class="content_title">{{ $t('msg.ONEX070G040.002') }}</h2> <!-- B/L 상태 -->
      <table class="tbl_col">
        <colgroup>
          <template v-if="memberDetail.userCtrCd === 'KR'">
            <col width="4%">
            <col width="12%">
            <col width="11%">
            <col width="13%">
            <col width="13%">
            <col width="16%">
            <col width="12%">
            <col width="11%">
            <col width="8%">
          </template>
          <template v-else>
            <col width="7%">
            <col width="15%">
            <col width="14%">
            <col width="16%">
            <col width="19%">
            <col width="15%">
            <col width="14%">
          </template>
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.ONEX070G040.003') }}</th> <!-- No -->
            <th>{{ $t('msg.ONEX070G040.004') }}</th> <!-- B/L No. -->
            <th>{{ $t('msg.ONEX070G040.005') }}</th> <!-- B/L 내용확인 -->
            <th>{{ $t('msg.ONEX070G040.006') }}</th> <!-- 운임 -->
            <th v-if="memberDetail.userCtrCd === 'KR'">{{ $t('msg.ONEX070G040.007') }}</th> <!-- 세금계산서 -->
            <th>{{ $t('msg.ONEX070G040.008') }}</th> <!-- 미정산금액 -->
            <th>{{ $t('msg.ONEX070G040.009') }}</th> <!-- 컨테이너 반입 -->
            <th>{{ $t('msg.ONEX070G040.010') }}</th> <!-- 상태 -->
            <th v-if="memberDetail.userCtrCd === 'KR'">{{ $t('msg.ONIM070P020.009') }}</th> <!-- 불가사유 -->
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in formData.items">
            <tr :key="'main_' + index">
              <td>{{ index + 1 }}</td>
              <td><a href="#">{{ item.blNo }}</a></td> <!-- B/L No.-->
              <td>{{ item.cfmStsNm }}</td> <!-- B/L 내용확인 -->
              <td>{{ item.setStsNm }}</td> <!-- 운임 -->
              <td v-if="memberDetail.userCtrCd === 'KR'">{{ item.issStsNm }}</td> <!-- 세금계산서 -->
              <td class="text_right" v-html="item.amt"></td> <!-- 미정산금액 -->
              <td>{{ item.cntrStsNm }}</td> <!-- 컨테이너 반입 -->
              <td><span class="label red md">{{ item.blStatusNm }}</span></td> <!-- 상태 -->
              <td v-if="memberDetail.userCtrCd === 'KR'">
                <button :class="item.errorFlag == 'Y' ? 'tbl_icon up' : 'tbl_icon down'" @click.prevent="errorShowing(item)">down</button>
              </td>
            </tr>
            <tr v-if="item.errorFlag == 'Y'" :key="'sub_' + index">
              <td colspan="9" class="pd0">
                <div class="inner_table blstatus">
                  <!--  B/L No.를 선택시 나오는 화면  -->
                  <table class="tbl_row t2">
                    <colgroup>
                      <col width="16%">
                      <col width="84%">
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <a class="button sh btn_error_area" @click.prevent="goBLModify(item.blNo, item)">{{ $t("msg.ONEX060P012.001") }}</a> <!-- B/L 수정 -->
                          <a class="button sh btn_error_area" @click.prevent="goFreetime(item.blNo)">{{ $t("msg.ONGO080G010.034") }}</a> <!-- DEM/DET/FREETIME -->
                        </td>
                        <td class="valign_top text_left">
                          <ul class="bul_list lh_short" v-for="(errorItem, errorIndex) in item.errorList" :key="index + '_' + errorIndex">
                            <li class="color_black">
                              {{ errorItem.blErrMsg }}
                              <template v-if="errorItem.blErrCd == '212' || errorItem.blErrCd == '235'">
                                <br />
                                <span class="color_red">({{ $t('msg.ONEX070G040.011') }} : {{ item.obrdDt }})</span> <!-- B/L On Board Date -->
                              </template>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div><!--  B/L No.를 선택시 나오는 화면 // -->
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>

    <h2 class="content_title"><span>{{ blStrings[0] }}</span><span style="color: red;">{{ blStrings[1] }}</span></h2> <!-- 요청 B/L 선택 -->
    <table class="tbl_row">
      <tbody>
        <tr>
          <td class="border_left_none">
            <template v-for="(item, index) in reqBizCatCdList">
              <input :disabled="issStstus === 'SUR_TEL' && (item.cd !== '02')" type="radio" :id="'reqBizCatCd_' + index" name="reqBizCatCd" :value="item.cd" v-model="formData.reqBizCatCd" :key="index" @change="setReqBizCatCd(item.cd)">
              <label :for="'reqBizCatCd_' + index" class="bl-highlight mr20" :key="'label_' + index"><span></span>{{ item.cdNm }}</label>
            </template>
          </td>
        </tr>
        <tr v-show="memberDetail.userCtrCd === 'IN'">
          <td class="border_left_none">
            <template v-for="(item, index) in docKndList">
              <input type="radio" :id="'docKndCd_' + index" name="docKndCd" :value="item.cd" v-model="formData.docKndCd" :key="index">
              <label :for="'docKndCd_' + index" class="bl-highlight mr20" :key="'label_' + index"><span></span>{{ item.cdNm }}</label>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <template v-if="formData.reqBizCatCd !== ''">
      <h2 class="content_title">{{ $t('msg.ONEX070G040.013') }}</h2> <!-- B/L 발행 요청인 -->
      <table class="tbl_row">
        <colgroup>
          <col width="170"><col>
        </colgroup>
        <tbody>
          <tr v-if="['IN','HK'].includes(memberDetail.userCtrCd)">
            <th>Upload Doc</th>
            <td>
              <div class="filebox" id="file_att">
                <span style="margin-right:5px;">
                  <select class="wid150 selectBlNo" style="background-color: white;" v-model="selectedEtcBlNo" @change.prevent="getEtcFileInfo()">
                    <option value="">{{ $t('msg.ONEX070G090.042') }}</option> <!-- 선택 -->
                    <option
                      v-for="item in etcBlNoList"
                      :key="item.blNo"
                      :style="item.succFlag === 'Y' ? 'color: gray; font-weight: bold;' : ''"
                    >
                      {{ item.blNo }}
                    </option>
                  </select>
                </span>
                <a
                  @click="uploadEtcPopup()"
                  v-if="etcUploadParam.length === 0"
                  class="button sm gray file"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
                <div v-if="etcUploadParam.length > 0">
                  <div v-for="etc in etcUploadParam" :key="etc.fileId">
                    <span class="tbl_icon attach"></span>
                    <a class="text_link">{{ etc.originalName }}</a>
                    <a class="tbl_icon attdel" @click="removeEtcUploadFile(selectedEtcBlNo)">X</a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="memberDetail.userCtrCd !== 'KR'">
            <th>Bank Slip</th> <!-- 振込明細 Upload -->
            <td>
              <div class="filebox" id="file_att">
                <span style="margin-right:5px;">
                  <select class="wid150 selectBlNo" style="background-color: white;" v-model="selectedJpBlNo" @change.prevent="getJpFileInfo()">
                    <option value="">{{ $t('msg.ONEX070G090.042') }}</option> <!-- 선택 -->
                    <option
                      v-for="item in jpBlNoList"
                      :key="item.blNo"
                      :style="item.succFlag === 'Y' ? 'color: gray; font-weight: bold;' : ''"
                    >
                      {{ item.blNo }}
                    </option>
                  </select>
                </span>
                <a
                  @click="uploadJpPopup()"
                  v-if="jpUploadParam.length === 0"
                  class="button sm gray file"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
                <div v-if="jpUploadParam.length > 0">
                  <div v-for="jp in jpUploadParam" :key="jp.fileId">
                    <span class="tbl_icon attach"></span>
                    <a class="text_link">{{ jp.originalName }}</a>
                    <a class="tbl_icon attdel" @click="removeJpUploadFile(selectedJpBlNo)">X</a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="memberDetail.userCtrCd !== 'KR' && memberDetail.userCtrCd !== 'JP'">
            <th>Withholding Tax</th>
            <td>
              <div class="filebox" id="file_att">
                <span style="margin-right:5px;">
                  <select class="wid150 selectBlNo" style="background-color: white;" v-model="selectedTaxBlNo" @change.prevent="getTaxFileInfo()">
                    <option value="">{{ $t('msg.ONEX070G090.042') }}</option> <!-- 선택 -->
                    <option
                      v-for="item in taxBlNoList"
                      :key="item.blNo"
                      :style="item.succFlag === 'Y' ? 'color: gray; font-weight: bold;' : ''"
                    >
                      {{ item.blNo }}
                    </option>
                  </select>
                </span>
                <a
                  @click="uploadTaxPopup()"
                  class="button sm gray file"
                  v-if="taxUploadParam.length === 0"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
                <div v-if="taxUploadParam.length > 0">
                  <span class="tbl_icon attach"></span>
                  <a class="text_link">{{ taxUploadParam[0].originalName }}</a>
                  <a class="tbl_icon attdel" @click="removeTaxUploadFile(selectedTaxBlNo)">X</a>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="formData.reqBizCatCd == '02'">
            <th>{{ $t('msg.ONEX070G040.014') }}</th> <!-- Surrender 요청서 -->
            <td>
              <div class="filebox" id="file_att">
                <div v-for="(item, idx) in uploadParam" :key="idx" :v-if="uploadParam.length > 0" style="width: 500px;">
                  <span class="tbl_icon attach"></span>
                  <a class="text_link">{{ item.originalName }}</a>
                  <a class="tbl_icon attdel" @click="removeUploadFile()">X</a>
                </div>
              </div>
              <a class="button sm" href="#" @click.prevent="searchSurrReq()">{{ $t('msg.ONEX070G040.015') }}</a> <!-- Surrender 요청서 다운 -->
              <!--<a class="button sm" href="#">Surrender B/L 발행 요청서 제출</a>-->
              <span class="position_relative">
                <button class="tbl_icon help ml5" @click.prevent="fnOpenPop('O')">도움말</button>
                <div v-if="popFlag" class="tooltip_wrap short position_absolute" style="width:300px; right:0; top:24px;"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button class="close" @click.prevent="fnOpenPop('C')">close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        {{ $t('msg.ONEX070G040.016') }} <!-- Surrendered B/L은 Surrender Fee 납부 후(일본,북중국 제외) ‘Surrender 요청서’ 출력 및 B/L Shipper 의 직인/명판 날인 후, 요청 가능합니다. -->
                        <template v-if="memberDetail.userCtrCd === 'KR'">
                          <br>
                          <p class="title mt10">{{ $t('msg.ONEX070G040.017') }}</p> <!-- 연락처 -->
                          {{ $t('msg.ONEX070G040.018') }}<br> <!-- - TEL : 051-797-7400 (내선6번) -->
                          {{ $t('msg.ONEX070G040.019') }} <!-- - FAX : 051-980-0504 -->
                        </template>
                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX070G040.020') }}</th> <!-- 신청인 -->
            <td>
              <input type="text" id="reqUsrNm" name="reqUsrNm" v-model="formData.reqUsrNm" class="col-6">
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX070G040.021') }}</th> <!-- 연락처 -->
            <td>
              <input type="text" id="mbpOfcNo" name="mbpOfcNo" maxlength="12" v-model="formData.mbpOfcNo" class="col-6">
            </td>
          </tr>

          <tr v-if="formData.reqBizCatCd === '06'">
            <th>{{ $t('msg.ONEX070G040.022') }}</th> <!-- 발행 지역 -->
            <td>
              <select name="issPlcCd" id="issPlcCd" class="col-6" v-model="formData.issPlcCd" @change.prevent="setEtcFlag">
                <option value="">{{ $t('msg.ONEX070G040.069') }}</option> <!-- 선택-->
                <option :id="plcCd.cd" v-for="(plcCd,index) in formData.issPlcCdArr" :key="index" :value="plcCd.cd">{{ plcCd.cdNm }}</option>
              </select>
              <template>
                <e-auto-complete-place v-if="etcFlag === 'Y'" @change="changePort" v-model="formData.ctrCd" placeholder="항구, 국가 입력(영어)" ctr-cd="KOR" />
              </template>
            </td>
          </tr>

          <tr>
            <th>{{ $t('msg.ONEX070G040.023') }}</th> <!-- B/L No. -->
            <td>
              {{ blNoStrings }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX070G040.024') }}</th> <!-- 요청내용(선택) -->
            <td>
              <!-- <textarea id="reqCont" name="reqCont" v-model="formData.reqCont" placeholder="O.B/L 관련 요청 및 입금 세부사항 기재 부탁드립니다."></textarea> -->
              <textarea id="reqCont" name="reqCont" v-model="formData.reqCont" :placeholder="$t('msg.ONEX070G040.071')"></textarea>
            </td>
          </tr>
          <tr v-if="formData.reqBizCatCd === '06'">
            <th>{{ $t('msg.ONEX070G040.026') }}</th> <!-- POR & DLY 표기여부 -->
            <td>
              <template v-for="(item, index) in formData.items">
                <input type="checkbox" :id="'porDly_' + index" name="pordly" :value="formData.items.blNo" :key="index" @click="changePolDlyArr(item.blNo)">
                <label :for="'porDly_' + index" class="mr20" :key="'label_' + index"><span></span>{{ item.blNo }}</label>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <!-- 팝업 관련 -->
    <win-layer-pop v-if="uploadFlag === 'Y' && uploadJp === 'Y'" ref="uploadPop" class="upload_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="jpUploadParam"
        :single-file-yn="singleFileYn"
        @set-dext-file-info="arrangeUploadParam"
        @close="closePopup"
      />
    </win-layer-pop>

    <win-layer-pop v-if="uploadFlag === 'Y' && uploadTax === 'Y'" ref="uploadPop" class="upload_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="taxUploadParam"
        :single-file-yn="true"
        @set-dext-file-info="arrangeUploadParam"
        @close="closePopup"
      />
    </win-layer-pop>

    <win-layer-pop v-if="uploadFlag === 'Y' && uploadEtc === 'Y'" ref="uploadPop" class="upload_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="etcUploadParam"
        :single-file-yn="singleFileYn"
        @set-dext-file-info="arrangeUploadParam"
        @close="closePopup"
      />
    </win-layer-pop>

    <win-layer-pop v-if="uploadFlag === 'N'" class="outer_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
        @callback="selectFunc"
      />
    </win-layer-pop>
    <!--  <div class="mt10 text_center">
      <a class="button blue lg" href="#" @click.prevent="fnPayment">발행요청</a>
      <a class="button gray lg" href="#" @click.prevent="$emit('close')">취소</a>
    </div> -->
  </div>
</template>

<script>
import auth from '@/api/services/auth'
import SurrenderRequestPop from '@/pages/trans/popup/SurrenderRequestPop'/* Surrender 요청서 팝업 */
import WinLayerPop from '@/pages/trans/popup/WinLayerPop' /* 팝업 관련 */
import DocUploadPop from '@/pages/trans/popup/DocUploadPop' /* 팝업 관련 */

import transCommon from '@/api/rest/trans/transCommon'
import blPayment from '@/api/rest/trans/blPayment'
import { rootComputed } from '@/store/helpers'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'

export default {
  name: 'BLPaymentTarget',
  components: {
    SurrenderRequestPop,
    WinLayerPop,
    DocUploadPop,
    EAutoCompletePlace,
    'bl-modity-noti-pop': () => import('@/pages/trans/popup/BLModifyNotiPop')
  },
  props: {
    blList: {
      type: Array,
      required: true
    },
    issStstus: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      lang: '',
      type: '', //old : 국가별로 화면이 다르기 때문에 type으로 제어
      blNoStrings: '',
      reqBizCatCdList: [
        { cd: '06', cdNm: this.$t('msg.ONEX070G040.029') }, //Original B/L
        { cd: '02', cdNm: this.$t('msg.ONEX070G040.030') }, //Surrendered B/L
        { cd: '03', cdNm: this.$t('msg.ONEX070G040.031') } //Sea Way Bill
      ],
      docKndList: [
        { cd: '04', cdNm: 'RFS(Receipt For Shipment) B/L' }, // RFS B/L
        { cd: '05', cdNm: 'SOB(Shipped on Board) B/L' } // SOB B/L
      ],
      formData: {
        blNoArr: [],
        items: [],
        reqBizCatCd: '',
        docKndCd: '',
        polDlyBlNoArr: [],
        issPlcCdArr: [],
        issPlcCd: '',
        reqCont: '', //요청 remark
        uploadFileInfos: [],
        payType: '',
        uploadjpTaxFileInfos: [],
        jpFileInfo: {}
      },
      customComponent: null, //팝업 관련
      selectFunc: null, //팝업 관련
      popParams: {}, //팝업 관련
      uploadParam: [], //팝업 관련,
      uploadJpParam: [], //팝업 관련,
      uploadTaxParam: [], //팝업 관련,
      uploadEtcParam: [], //팝업 관련,
      uploadFlag: 'N',
      uploadJp: 'N',
      uploadTax: 'N',
      uploadEtc: 'N',

      popFlag: false,
      etcFlag: 'N',
      tvo: {},
      blStrings: ['', ''],

      selectedJpBlNo: '',
      selectedTaxBlNo: '',
      selectedEtcBlNo: '',

      jpBlNoList: [],
      taxBlNoList: [],
      etcBlNoList: [],

      jpUploadParam: [],
      jpSuccBlNo: [],

      taxUploadParam: [],
      taxSuccBlNo: [],

      etcUploadParam: [],
      etcSuccBlNo: [],

      surrFileYn: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    blList: {
      deep: true,
      handler () {
        this.init()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      console.log('@@@@ mounted')
      let s = this.$t('msg.ONEX070G040.012')
      console.log('@@@@ s: ', s)
      this.blStrings[0] = s.substring(0, s.indexOf('※'))
      this.blStrings[1] = s.substring(s.indexOf('※'))
    })
  },
  created () {
    this._ = require('lodash')
    //국가별 분기 셋팅
    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
      this.setNationalType(this.lang)
      this.formData.reqUsrNm = this.auth.userName
    }

    this.init()
  },
  methods: {
    async init () {
      const TH = this

      if (this.auth !== undefined) {
        await auth.info(this.auth.jwt).then(res => {
          const obj = res.data
          let usrTel = ''

          if (this.$ekmtcCommon.isNotEmpty(obj)) {
                if (this.$ekmtcCommon.isNotEmpty(obj.cel1)) {
              usrTel += obj.cel1
            }
            if (this.$ekmtcCommon.isNotEmpty(obj.cel2)) {
              usrTel += obj.cel2
            }
            if (this.$ekmtcCommon.isNotEmpty(obj.cel3)) {
              usrTel += obj.cel3
            }
            if (this.$ekmtcCommon.isNotEmpty(obj.cel4)) {
              usrTel += obj.cel4
            }

            this.formData.mbpOfcNo = usrTel
          }
        })
      }
      let resultArr = []
      this.blList.map((item) => {
        if (!resultArr.includes(item.blNo)) {
          resultArr.push(item.blNo)
        }
      })
      this.formData.blNoArr = resultArr

      //BL No. selectBox 세팅하기
      // succFlag 추가
      if (resultArr.length > 0) {
        for (let i = 0; i < resultArr.length; i++) {
          let tmpBlInfo = {
            blNo: resultArr[i],
            succFlag: 'N'
          }

          // blNoList에 넣기
          TH.jpBlNoList.push(tmpBlInfo)
          TH.taxBlNoList.push(tmpBlInfo)
          TH.etcBlNoList.push(tmpBlInfo)
        }
      }

      //BL_NO가 1개일 경우 selectBox 초기화셋팅
      if (TH.jpBlNoList.length === 1) {
        TH.selectedJpBlNo = TH.jpBlNoList[0].blNo

        this.getJpFileInfo()
      }

      if (TH.taxBlNoList.length === 1) {
        TH.selectedTaxBlNo = TH.taxBlNoList[0].blNo

        this.getTaxFileInfo()
      }

      if (TH.etcBlNoList.length === 1) {
        TH.selectedEtcBlNo = TH.etcBlNoList[0].blNo

        this.getEtcFileInfo()
      }

      // //국가별 issPlcCd 셋팅
      // if (this.type === 'KO') {
      //   this.formData.issPlcCdArr = [
      //     { cd: 'SEL', cdNm: this.$t('msg.ONEX070G040.032') }, //서울
      //     { cd: 'INC', cdNm: this.$t('msg.ONEX070G040.033') }, //인천
      //     { cd: 'PUS', cdNm: this.$t('msg.ONEX070G040.034') }, //부산
      //     { cd: 'USN', cdNm: this.$t('msg.ONEX070G040.035') }, //울산
      //     { cd: 'ETC', cdNm: this.$t('msg.ONEX070G040.036') } //국외
      //   ]
      // } else if (this.type === 'TH') {
      //   this.formData.issPlcCdArr = [
      //     { cd: 'BKK', cdNm: this.$t('msg.ONEX070G040.037') }, //BANGKOK
      //     { cd: 'LCH', cdNm: this.$t('msg.ONEX070G040.038') }, //LAEM CHABANG
      //     { cd: 'ETC', cdNm: this.$t('msg.ONEX070G040.036') } //국외
      //   ]
      // } else if (this.type === 'JP') {
      //   this.formData.issPlcCdArr = [
      //     { cd: 'OSA', cdNm: this.$t('msg.ONEX070G040.039') }, //OSAKA
      //     { cd: 'TYO', cdNm: this.$t('msg.ONEX070G040.040') }, //TOKYO
      //     { cd: 'ETC', cdNm: this.$t('msg.ONEX070G040.036') } //국외
      //   ]
      // }

      // 발행지역 코드를 공통코드에서 조회
      if (this.memberDetail) {
        await transCommon.findBlIssuePlace({ ctrCd: this.memberDetail.userCtrCd }).then(res => {
          let plcCdArr = res.data || []
          plcCdArr.push({ cd: 'ETC', cdNm: this.$t('msg.ONEX070G040.036') }) // 국외
          this.formData.issPlcCdArr = plcCdArr
        })
      }

      //radio button default(Original B/L)
      // this.formData.reqBizCatCd = '06'

      if (this.$props.issStstus === 'SUR_TEL') {
        // (인도네시아)진행중업무에서 O.B/L 발행후 SURR B/L 신청할때
        this.formData.reqBizCatCd = '02'
        this.setReqBizCatCd('02')
      } else {
        this.formData.reqBizCatCd = ''
        this.setReqBizCatCd('')
      }

      // 인도일때, O.B/L 세팅
      if (this.memberDetail.userCtrCd === 'IN') {
        this.formData.reqBizCatCd = '06'
        this.setReqBizCatCd('06')
      }

      //BL String화
      this.blNoStrings = this.formData.blNoArr.toString()

      const result = await blPayment.findBlPaymentDetail(this.formData)
      const newResult = result.data.reverse()

      this.formData.items = newResult.map((item) => {
        let txtAmtUsd = this.$ekmtcCommon.changeNumberFormat(item.amtUsd, { isComma: true }) || 0
        let txtAmtLocal = this.$ekmtcCommon.changeNumberFormat(item.amtLocal, { isComma: true }) || 0

        if (this.$ekmtcCommon.isNotEmpty(item.outAmt) && Number(item.outAmt) === 0) {
          txtAmtUsd = 0
          txtAmtLocal = 0
        }
        //미정산금액
        let curr = 'KRW '

        if (this.memberDetail.userCtrCd !== 'KR' && item.locCurCd !== undefined) {
          curr = item.locCurCd + ' '
        }

        item.amt = 'USD ' + txtAmtUsd + '</br>' + curr + txtAmtLocal

        //상태
        let blStatus = item.blStatus
        if (blStatus === '11') {
          item.blStatusNm = this.$t('msg.ONEX070G040.041') //O.B/L 재발행
        } else if (blStatus === '01') {
          item.blStatusNm = this.$t('msg.ONEX070G040.042') //O.B/L 발행
        } else if (blStatus === '02') {
          item.blStatusNm = this.$t('msg.ONEX070G040.043') //Surrender 발행
        } else if (blStatus === '03') {
          item.blStatusNm = this.$t('msg.ONEX070G040.044') //S/Waybill 발행
        } else if (blStatus === '04') {
          item.blStatusNm = this.$t('msg.ONEX070G040.045') //S/Waybill 발행중
        } else if (blStatus === '06') {
          item.blStatusNm = this.$t('msg.ONEX070G040.046') //O.B/L 발행중
        } else if (blStatus === '07') {
          item.blStatusNm = this.$t('msg.ONEX070G040.047') //Surrender 처리중
        } else if (blStatus === '14') {
          item.blStatusNm = this.$t('msg.ONEX070G040.048') //S/Waybill 승인
        } else if (blStatus === '16') {
          item.blStatusNm = this.$t('msg.ONEX070G040.049') //O.B/L 승인
        } else if (blStatus === '17') {
          item.blStatusNm = this.$t('msg.ONEX070G040.050') //Surrender 승인
        } else if (blStatus === '42') {
          item.blStatusNm = this.$t('msg.ONEX070G040.051') //Surrender 취소
        } else if (blStatus === '46') {
          item.blStatusNm = this.$t('msg.ONEX070G040.052') //S/Waybill 취소
        } else if (blStatus === '88') {
          item.blStatusNm = this.$t('msg.ONEX070G040.053') //발행불가
        } else if (blStatus === '89') {
          item.blStatusNm = this.$t('msg.ONEX070G040.054') //발행불가(Pending)
        } else if (blStatus === '99') {
          if (item.cntrStsCd === 'N' && item.elYn === 'N') {
            item.blStatusNm = this.$t('msg.ONEX070G040.055') //요청불가 -> 컨테이너 미반입, 면장 미입력
          } else if (item.elYn === 'N') {
            item.blStatusNm = this.$t('msg.ONEX070G040.055') //요청불가 -> 면장 미입력
          } else if (item.cntrStsCd === 'N' && item.cntrCfsYn === 'N') {
            item.blStatusNm = this.$t('msg.ONEX070G040.055') //요청불가 -> 컨테이너 미반입
          } else {
            item.blStatusNm = this.$t('msg.ONEX070G040.056') //미요청
          }
        }

        //ERROR 활성화 관련 플래그
        item.errorFlag = 'N'

        //코드별 네임 셋팅: B/L 내용 확인
        item.cfmStsNm = item.cfmStsCd === '01' ? this.$t('msg.ONEX070G040.057') : this.$t('msg.ONEX070G040.058') //승인, 대기

        //코드별 네임 셋팅: 운임
        let setStsCd = item.setStsCd
        if (setStsCd === '01') {
          item.setStsNm = this.$t('msg.ONEX070G040.059') //미정산(영수)
        } else if (setStsCd === '02') {
          item.setStsNm = this.$t('msg.ONEX070G040.060') //미정산(청구)
        } else if (setStsCd === '03') {
          item.setStsNm = this.$t('msg.ONEX070G040.061') //미정산(월말청구)
        } else if (setStsCd === '11') {
          item.setStsNm = this.$t('msg.ONEX070G040.062') //정산완료(영수)
        } else if (setStsCd === '12') {
          item.setStsNm = this.$t('msg.ONEX070G040.063') //정산완료(청구)
        } else if (setStsCd === '13') {
          item.setStsNm = this.$t('msg.ONEX070G040.064') //정산완료(월말청구)
        }

        //코드별 네임 셋팅: 세금게산서
        let issStsCd = item.issStsCd
        this.$emit('setTaxInvoice', issStsCd)
        if (issStsCd === '01') {
          item.issStsNm = this.$t('msg.ONEX070G040.065') //미발행
        } else if (issStsCd === '02') {
          item.issStsNm = this.$t('msg.ONEX070G040.066') //발행
        }

        //코드별 네임 셋팅: 컨테이너 반입
        let cntrStsCd = item.cntrStsCd
        if (cntrStsCd === 'Y') {
          item.cntrStsNm = this.$t('msg.ONEX070G040.067') //반입완료
        } else if (cntrStsCd === 'N') {
          item.cntrStsNm = this.$t('msg.ONEX070G040.068') //미반입
        }

        //

        this.errorShowing(item)
        return item
      })
    },
    //국가별 코드 셋팅(화면 및 rest api 분기용)
    setNationalType (lang) {
      if (lang === 'KOR') {
        this.type = 'KO'
      } else {
        if (this.memberDetail !== undefined) {
          if (this.memberDetail.userCtrCd === 'TH') {
            this.type = 'TH'
          } else if (this.memberDetail.userCtrCd === 'JP') {
            this.type = 'JP'
          } else {
            this.type = 'EN'
          }
        }
      }
    },
    //에러보여주기
    errorShowing (item) {
      if (item.errorFlag === 'Y') {
        item.errorFlag = 'N'
      } else {
        item.errorFlag = 'Y'
      }
    },
    //발행요청
    async fnPayment (payType) {
      //입금방식 셋팅
      this.formData.payType = payType

      //지역선택값 국외인 경우 발행지 셋팅
      if (this.etcFlag === 'Y') {
        this.formData.ctrCd = this.tvo.ctrCd
        this.formData.issPlcCd = this.tvo.plcCd
      }
      //업로드 오브젝트 셋팅
      this.formData.uploadFileInfos = this.uploadParam

      const result = await blPayment.insertBlRequest(this.formData)
      return result
    },
    //발행요청2
    async fnPayment2 (req) {
      // 인도일때, upload doc 첨부파일 필수체크
      if (this.memberDetail.userCtrCd === 'IN') {
        // O.B/L, Sea Waybill, surrender 선택 필수체크
        if (this.$ekmtcCommon.isEmpty(this.formData.reqBizCatCd)) {
            this.$ekmtcCommon.alertDefault('Please select Original B/L, Surrendered B/L, Sea Waybill.')
            return
        }

        // RFS, SOB B/L 선택 필수체크
        if (this.$ekmtcCommon.isEmpty(this.formData.docKndCd)) {
            this.$ekmtcCommon.alertDefault('Please select RFS B/L or SOB B/L.')
            return
        }

        if (this.$ekmtcCommon.isEmpty(this.etcUploadParam)) {
          this.$ekmtcCommon.alertDefault('Please attach the Upload Doc file.')
          return
        }

        // 서렌더 B/L 첨부파일 체크
        if (this.formData.reqBizCatCd === '02' && this.surrFileYn === 'N') {
          this.$ekmtcCommon.alertDefault('Please attach Surrendered B/L Request Submission file.')
          return
        }
      }

      //입금방식 셋팅
      this.formData.payType = req.payType
      this.formData.payAcctList = req.payAcctList

      //지역선택값 국외인 경우 발행지 셋팅
      if (this.etcFlag === 'Y') {
        this.formData.ctrCd = this.tvo.ctrCd
        this.formData.issPlcCd = this.tvo.plcCd
      }
      //업로드 오브젝트 셋팅
      this.formData.uploadFileInfos = this.uploadParam

      // 파일데이터 파라이터 초기화해줌
      this.formData.uploadjpTaxFileInfos = []

      // 파라미터 세팅
      for (var jp of this.jpSuccBlNo) {
        jp.fileInfo.forEach((jpf, idx) => {
          jpf.atchFileCatCd = jp.atchFileCatCd
          jpf.blNo = jp.blNo
          jpf.atchFileSeq = (idx + 1)

          this.formData.uploadjpTaxFileInfos.push(jpf)
        })

        // jp.fileInfo[0].atchFileCatCd = jp.atchFileCatCd
        // jp.fileInfo[0].blNo = jp.blNo
      }

      for (var tax of this.taxSuccBlNo) {
        tax.fileInfo[0].atchFileCatCd = tax.atchFileCatCd
        tax.fileInfo[0].blNo = tax.blNo
        tax.fileInfo[0].atchFileSeq = 1

        this.formData.uploadjpTaxFileInfos.push(tax.fileInfo[0])
      }

      for (var etc of this.etcSuccBlNo) {
        etc.fileInfo.forEach((etcf, idx) => {
          etcf.atchFileCatCd = etc.atchFileCatCd || this.formData.docKndCd
          etcf.blNo = etc.blNo
          etcf.atchFileSeq = (idx + 1)

          this.formData.uploadjpTaxFileInfos.push(etcf)
        })
      }

      // console.log('this.formData.uploadjpTaxFileInfos @@@@@ ', this.formData.uploadjpTaxFileInfos)

      const result = await blPayment.insertBlRequest(this.formData)
      return result
    },
    //POL & DLY 체크
    changePolDlyArr (blNo) {
      let index = this.formData.polDlyBlNoArr.indexOf(blNo)
      if (index > -1) {
        this.formData.polDlyBlNoArr.splice(index, 1)
      } else {
        this.formData.polDlyBlNoArr.push(blNo)
      }
    },
    //Surrender 요청서 다운 팝업 관련
    searchSurrReq () {
      this.selectFunc = this.callbackSearchSurrReq

      //팝업 플래그 처리
      this.uploadFlag = 'N'

      this.popParams = {
        blNoArr: this.formData.blNoArr
      }

      this.openPopup('SurrenderRequestPop')
    },
    callbackSearchSurrReq (obj) {
      console.log('##### callback #####')
      console.log(obj)
      if (obj !== undefined) {
        obj.forEach(item => {
          this.uploadParam.push(item)
        })

        this.surrFileYn = 'Y'
      }
    },
    openPopup (compNm, alertFlag) {
      this.customComponent = compNm

      if (this.selectedJpBlNo === '' && alertFlag === 'J') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.047'))
        return
      }

      if (this.selectedTaxBlNo === '' && alertFlag === 'T') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.047'))
        return
      }

      if (this.selectedEtcBlNo === '' && alertFlag === 'E') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.047'))
        return
      }

      if (this.uploadFlag === 'Y') {
        this.$ekmtcCommon.layerOpen('.bl_payment_pop .upload_popup_dim')
      } else {
        this.$ekmtcCommon.layerOpen('.bl_payment_pop .outer_popup_dim')
      }
    },
    closePopup (obj) {
      console.log('##### close #####')

      if (this.customComponent === 'SurrenderRequestPop' && obj !== undefined) {
        this.uploadParam = obj
        this.customComponent = null
      }

      if (this.uploadFlag === 'Y') {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.bl_payment_pop .upload_popup_dim')
      } else {
        this.$ekmtcCommon.layerClose('.bl_payment_pop .outer_popup_dim')
      }
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam

      //팝업 플래그 처리
      this.uploadFlag = 'Y'
      this.uploadJp = 'N'

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    uploadJpPopup () {
      this.uploadFlag = 'Y'
      this.uploadJp = 'Y'
      this.uploadTax = 'N'
      this.uploadEtc = 'N'

      this.singleFileYn = false
      const uploadParam = this.jpUploadParam

      this.$set(this, 'jpUploadParam', uploadParam)

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.jpFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.jpFileInfo })

      //   if (this.uploadJpParam.length === 0) {
      //     this.uploadJpParam.push({ ...this.formData.jpFileInfo })
      //   }
      // }

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.taxFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.taxFileInfo })
      // }

      // this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop', 'J')
    },
    uploadTaxPopup () {
      this.uploadFlag = 'Y'
      this.uploadTax = 'Y'
      this.uploadJp = 'N'
      this.uploadEtc = 'N'
      this.singleFileYn = true
      const uploadParam = this.taxUploadParam

      this.$set(this, 'taxUploadParam', uploadParam)

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.taxFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.taxFileInfo })

      //   if (this.uploadTaxParam.length === 0) {
      //     this.uploadTaxParam.push({ ...this.formData.taxFileInfo })
      //   }
      // }

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.jpFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.jpFileInfo })
      // }

      // this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop', 'T')
    },
    uploadEtcPopup () {
      this.uploadFlag = 'Y'
      this.uploadEtc = 'Y'
      this.uploadTax = 'N'
      this.uploadJp = 'N'
      this.singleFileYn = false
      const uploadParam = this.etcUploadParam

      this.$set(this, 'etcUploadParam', uploadParam)
      this.openPopup('DocUploadPop', 'E')
    },
    arrangeUploadParam (file) {
      const TH = this
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)

      console.log('arrangeUploadParam @@@@', file)
      if (TH.uploadJp !== 'Y' && TH.uploadTax !== 'Y' && TH.uploadEtc !== 'Y') {
        TH.uploadParam.map((item) => (item.newFlag = item.new))
      } else {
          if (file.length > 0) {
              if (TH.uploadTax === 'Y') {
                this.taxUploadParam.map((item) => (item.newFlag = item.new))

                TH.taxUploadParam = this._.cloneDeep(file)

                //succbl배열에 넣어주기
                TH.taxSuccBlNo.push(
                  {
                    blNo: TH.selectedTaxBlNo,
                    agreeYn: 'Y',
                    disabled: 'Y',
                    fileInfo: TH.taxUploadParam,
                    atchFileCatCd: '02'
                  }
                )
                //succFlag바꿔주기
                TH.taxBlNoList.forEach(item => {
                  if (item.blNo === TH.selectedTaxBlNo) {
                    item.succFlag = 'Y'
                  }
                })
              } else if (TH.uploadJp === 'Y') {
                this.jpUploadParam.map((item) => (item.newFlag = item.new))

                TH.jpUploadParam = this._.cloneDeep(file)

                //succbl배열에 넣어주기
                TH.jpSuccBlNo.push(
                  {
                    blNo: TH.selectedJpBlNo,
                    agreeYn: 'Y',
                    disabled: 'Y',
                    fileInfo: TH.jpUploadParam,
                    atchFileCatCd: '01'
                  }
                )
                //succFlag바꿔주기
                TH.jpBlNoList.forEach(item => {
                  if (item.blNo === TH.selectedJpBlNo) {
                    item.succFlag = 'Y'
                  }
                })
              } else if (TH.uploadEtc === 'Y') {
                this.etcUploadParam.map((item) => (item.newFlag = item.new))

                TH.etcUploadParam = this._.cloneDeep(file)

                //succbl배열에 넣어주기
                TH.etcSuccBlNo.push(
                  {
                    blNo: TH.selectedEtcBlNo,
                    agreeYn: 'Y',
                    disabled: 'Y',
                    fileInfo: TH.etcUploadParam,
                    atchFileCatCd: TH.formData.docKndCd || '04' // 인도일때는 필수선택, 나머지 국가 기본값 '04'
                  }
                )
                //succFlag바꿔주기
                TH.etcBlNoList.forEach(item => {
                  if (item.blNo === TH.selectedEtcBlNo) {
                    item.succFlag = 'Y'
                  }
                })
              }
          } else {
              if (TH.uploadTax === 'Y') {
                TH.taxUploadParam = {}
              } else if (TH.uploadJp === 'Y') {
                TH.jpUploadParam = {}
              } else {
                TH.etcUploadParam = {}
              }
          }
      }
    },
    removeUploadFile () {
      this.uploadParam.splice(0, 1)
      this.customComponent = null

      // 서렌더 첨부파일 체크
      this.surrFileYn = 'N'
    },
    removeJpUploadFile (blNo) {
      this.jpUploadParam.splice(0, 1)

      const removeIndex = this.jpSuccBlNo.findIndex(el => el.blNo === blNo)

      if (removeIndex >= 0) {
        this.jpSuccBlNo.splice(removeIndex, 1)
        // this.setAllSaveYn()
      }
      this.customComponent = null
    },
    removeTaxUploadFile (blNo) {
      this.taxUploadParam.splice(0, 1)

      const removeIndex = this.taxSuccBlNo.findIndex(el => el.blNo === blNo)

      if (removeIndex >= 0) {
        this.taxSuccBlNo.splice(removeIndex, 1)
        // this.setAllSaveYn()
      }
      this.customComponent = null
    },
    removeEtcUploadFile (blNo) {
      this.etcUploadParam.splice(0, 1)

      const removeIndex = this.etcSuccBlNo.findIndex(el => el.blNo === blNo)

      if (removeIndex >= 0) {
        this.etcSuccBlNo.splice(removeIndex, 1)
        // this.setAllSaveYn()
      }
      this.customComponent = null
    },
    //국가,포트 변경시 값 셋팅(자동완성)
    changePort (vo) {
      console.log('vo!!', vo)
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }
      this.tvo = tvo
    },
    fnOpenPop (flag) {
      if (flag === 'O') {
        this.popFlag = true
      } else {
        this.popFlag = false
      }
    },
    //초기화
    reset () {
      this.lang = ''
      this.type = ''
      this.blNoStrings = ''
      this.formData = {
        blNoArr: [],
        items: [],
        reqBizCatCd: '',
        docKndCd: '',
        polDlyBlNoArr: [],
        issPlcCdArr: [],
        issPlcCd: '',
        reqCont: ''
      }
      this.customComponent = null
      this.selectFunc = null
      this.popParams = {}
      this.uploadParam = []
      this.popFlag = false
      this.jpUploadParam = []
      this.taxUploadParam = []
      this.etcUploadParam = []
    },
    //벨리데이션을 위한 카테고리코드 emit
    setReqBizCatCd (reqBizCatCd) {
      const elemMessage = document.querySelectorAll('.tooltip_essen')
      const elemForm = document.querySelectorAll('.input_error')

      let len = elemMessage.length

      for (let i = 0; i < len; i++) {
        elemMessage[i].style.display = 'none'
      }

      len = elemForm.length

      for (let i = 0; i < len; i++) {
        this.$ekmtcCommon.elemRemoveClass(elemForm[i], 'input_error')
      }
      this.$emit('setReqBizCatCd', reqBizCatCd)

      if (reqBizCatCd !== '06') {
        this.$set(this.formData, 'polDlyBlNoArr', [])
      }
    },
    setEtcFlag () {
      if (this.formData.issPlcCd === 'ETC') {
        this.etcFlag = 'Y'
      } else {
        this.etcFlag = 'N'
      }
    },
    emitData (type) {
      let res = null
      const TH = this

      switch (type) {
        case 'bl':
          this.formData.uploadFileInfos = this.uploadParam

          for (var jp of this.jpSuccBlNo) {
            jp.fileInfo[0].atchFileCatCd = jp.atchFileCatCd
            jp.fileInfo[0].blNo = jp.blNo

            this.formData.uploadjpTaxFileInfos.push(jp.fileInfo)
          }

          for (var tax of this.taxSuccBlNo) {
            tax.fileInfo[0].atchFileCatCd = tax.atchFileCatCd
            tax.fileInfo[0].blNo = tax.blNo

            this.formData.uploadjpTaxFileInfos.push(tax.fileInfo[0])
          }

          for (var etc of this.etcSuccBlNo) {
            etc.fileInfo[0].atchFileCatCd = etc.atchFileCatCd
            etc.fileInfo[0].blNo = etc.blNo

            this.formData.uploadjpTaxFileInfos.push(etc.fileInfo)
          }

          res = JSON.parse(JSON.stringify(this.formData))
          break
      }
      return res
    },
    async goBLModify (blNo) {
      let auth = ''
      const authParams = {
        bizCd: 'BL',
        rqRno: blNo,
        eiCatCd: ''
      }
      await transCommon.isHaveAuth(authParams).then(async res => {
        auth = res.data
      })
      if (auth !== 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
        return
      }

      this.fnNoticePop(blNo)
    },
    async fnNoticePop (blNo) {
      let bkgNo = ''
      await transCommon.getBkgNo(blNo).then(res => {
        bkgNo = res.data
      })
      this.selectFunc = this.callBackNotice
      this.popParams = {
        bkgNo: bkgNo,
        blNo: blNo
      }

      this.openPopup('bl-modity-noti-pop')
    },
    callBackNotice (vo) {
      this.$ekmtcCommon.layerClose('.bl_payment_pop .outer_popup_dim')
      this.$router.push({ path: '/working/detail/bl', name: 'bl', params: { blNo: vo.blNo, bkgNo: vo.bkgNo } }).catch(() => {})
    },
    goFreetime (blNo) {
      const query = {
        eiCatCd: 'O',
        dtKnd: 'BL',
        num: blNo
      }
      this.$router.push({ name: 'FreeTimeRequest', query: query })
    },
    setRcqCont (reqCont, rcvProcCatCd) {
      console.log('>>>>> BLPaymentTarget : ' + reqCont + ', ' + rcvProcCatCd)
      if (rcvProcCatCd === '02') {
        if (this.formData.reqCont.indexOf(reqCont) < 0) {
          // 요청내용(선택) 값 변경
          this.formData.reqCont = reqCont + '\r\n' + this.formData.reqCont
        }
      }
    },
    //파일 여부 가져오기
    getJpFileInfo () {
      const TH = this
      const selectedBlNo = TH.selectedJpBlNo

      TH.jpUploadParam = []

      if (TH.jpSuccBlNo.length > 0) {
        TH.jpSuccBlNo.forEach(item => {
          if (selectedBlNo === item.blNo) {
            TH.jpUploadParam = item.fileInfo
          }
        })
      }
    },
    //파일 여부 가져오기
    getTaxFileInfo () {
      const TH = this
      const selectedBlNo = TH.selectedTaxBlNo

      TH.taxUploadParam = []

      if (TH.taxSuccBlNo.length > 0) {
        TH.taxSuccBlNo.forEach(item => {
          if (selectedBlNo === item.blNo) {
            TH.taxUploadParam = item.fileInfo
          }
        })
      }
    },
    getEtcFileInfo () {
      const TH = this
      const selectedBlNo = TH.selectedEtcBlNo

      TH.etcUploadParam = []

      if (TH.etcSuccBlNo.length > 0) {
        TH.etcSuccBlNo.forEach(item => {
          if (selectedBlNo === item.blNo) {
            TH.etcUploadParam = item.fileInfo
          }
        })
      }
    }
  }
}
</script>

<style scoped>
  .btn_error_area {width: 110px; font-size: 12px; text-align: center;}
  .btn_error_area + .btn_error_area {margin-top: 5px;}
  .bl-highlight {
    font-weight: bold;
    font-size: 20px;
  }
  .border_left_none {
    height: 45px;
  }
  .content_title span {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
</style>
